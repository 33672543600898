import React, { useCallback, useRef, useEffect, useState } from "react";
import {
  IonPage,
  IonCheckbox,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonDatetime,
  IonIcon,
  IonItem,
  IonInput,
  IonButton,
  IonList,
} from "@ionic/react";
import {
  calendar,
  contact,
  stopwatch,
  alarm,
  create,
  repeat,
  wifi,
} from "ionicons/icons";
import { cx } from "emotion";

import * as css from "./BookingEdit.css";
import useStore, { Booking } from "../store.app";
import { RouteComponentProps, withRouter } from "react-router";
import {
  timeStringToDate,
  getTimeString,
  inPeriod,
  isRepeatSameAs,
  repeatDates,
} from "../utils";
import config from "../config";
import dayjs from "dayjs";
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

const timeRange: any[] = [];
[...Array(config.endHour + 1).keys()]
  .slice(config.startHour - config.endHour - 1)
  .forEach((x) => {
    "00,15,30,45"
      .split(",")
      .forEach((y) => timeRange.push(`${x.toString().padStart(2, "0")}:${y}`));
  });

const Editor: React.FC<RouteComponentProps> = ({ history }) => {
  const { bookingInEditor, dispatch, bookings } = useStore(
    "bookingInEditor",
    "bookings"
  );
  const refDate = useRef();
  const refStart = useRef();
  const refEnd = useRef();
  const refNote = useRef();
  const refMembers = useRef();
  const refRepeat = useRef();
  const refRepeatTimes = useRef();
  const refRepeatEndDate = useRef();
  const refOnline = useRef();

  const [showDelPop, setShowDelPop] = useState(false);
  const [showSavePop, setShowSavePop] = useState(false);

  const readonly =
    bookingInEditor.id && bookingInEditor.endTime.isBefore(dayjs());

  const updateDate = useCallback(
    (e) => {
      const value = e.detail.value;
      if (!value) return;
      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        startTime: timeStringToDate(
          getTimeString(bookingInEditor?.startTime),
          value
        ),
        endTime: timeStringToDate(
          getTimeString(bookingInEditor?.endTime),
          value
        ),
      });
    },
    [bookingInEditor, dispatch]
  );

  const updateRepeat = useCallback(
    (e) => {
      const value = e.target.value;
      const repeat = value == null ? value : parseInt(value.toString() || "0");
      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        repeat,
        ...(repeat ? {} : { repeatTimes: null, repeatEndDate: null }),
      });
    },
    [bookingInEditor, dispatch]
  );

  const updateRepeatTimes = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        repeatEndDate: null,
        repeatTimes: value == null ? value : parseInt(value.toString() || "-1"),
      });
    },
    [bookingInEditor, dispatch]
  );

  const updateRepeatEndDate = useCallback(
    (e) => {
      const value = e.detail.value;
      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        repeatTimes: null,
        repeatEndDate:
          value == null ? value : timeStringToDate("23:59:59", value),
      });
    },
    [bookingInEditor, dispatch]
  );

  const updateTime = useCallback(
    (e) => {
      // const value = e.detail.value;
      const value = e.target.value;
      if (!value) return;

      const startEl = refStart.current as any;
      const endEl = refEnd.current as any;

      if (e.target === startEl) {
        // startEl.value = value;
        if (endEl.value <= value) {
          endEl.value = getTimeString(timeStringToDate(value).add(1, "hour"));
        }
      }
      if (e.target === endEl) {
        // endEl.value = value;
        if (startEl.value >= value) {
          startEl.value = getTimeString(
            timeStringToDate(value).add(-1, "hour")
          );
        }
      }

      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        startTime: timeStringToDate(startEl.value, bookingInEditor?.startTime),
        endTime: timeStringToDate(endEl.value, bookingInEditor?.endTime),
      });
    },
    [bookingInEditor, dispatch]
  );

  const updateNote = useCallback(
    (e) => {
      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        note: e.detail.value,
      });
    },
    [bookingInEditor, dispatch]
  );

  const updateOnline = useCallback(
    (e) => {
      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        online: !!e.detail.checked,
      });
    },
    [bookingInEditor, dispatch]
  );

  const deleteBooking = useCallback(() => {
    if (window.confirm("确定删除吗？")) {
      dispatch("deleteBooking", bookingInEditor.id);
      history.replace("/");
    }
  }, [bookingInEditor, dispatch, history]);

  const deleteRepeatBookings = useCallback(() => {
    if (window.confirm("确定删除未来所有重复的预定吗？")) {
      dispatch("deleteRepeatBookings", bookingInEditor);
      history.replace("/");
    }
  }, [bookingInEditor, dispatch, history]);

  const isRepeatable = useCallback(
    (bookingInEditor: Booking) => {
      const bs = bookings || [];
      // const origin = bs.find((x) => x.id === bookingInEditor.id);
      // if (isRepeatSameAs(bookingInEditor, origin)) return true;

      const allStarts = repeatDates(bookingInEditor.startTime, bookingInEditor);
      const allEnds = repeatDates(bookingInEditor.endTime, bookingInEditor);
      for (var i = 0; i < allStarts.length; i++) {
        const startTime = allStarts[i];
        const endTime = allEnds[i];
        const conflict = bs.find(
          (x) =>
            x.repeatSeries !== bookingInEditor.repeatSeries &&
            (inPeriod(startTime, x) ||
              inPeriod(x.startTime, { startTime, endTime }))
        );
        if (conflict) {
          window.alert(
            `课程时间有冲突！${conflict.startTime.format(
              "YYYY-MM-DD HH:mm"
            )}已有课程安排`
          );
          return false;
        }
      }
      return true;
    },
    [bookings]
  );

  const saveBooking = useCallback(
    (saveRepeat?: boolean) => {
      const bs = bookings || [];
      if (!bookingInEditor) return;

      if (!bookingInEditor.customers || !bookingInEditor.customers) {
        alert("必须选择会员");
        return;
      }

      if (!bookingInEditor.startTime || !bookingInEditor.endTime) {
        alert("必须设定起始时间");
        return;
      }

      const conflict = bs.find(
        (x) =>
          x.id !== bookingInEditor.id &&
          (inPeriod(bookingInEditor.startTime, x) ||
            inPeriod(x.startTime, bookingInEditor))
      );
      if (conflict) {
        window.alert(
          `课程时间有冲突！${conflict.startTime.format(
            "YYYY-MM-DD HH:mm"
          )}已有课程安排`
        );
        return;
      }

      if (saveRepeat && !isRepeatable(bookingInEditor)) return;

      dispatch("saveBooking", {
        booking: {
          ...bookingInEditor,
          customers: [...bookingInEditor.customers],
        },
        saveRepeat,
      });
      history.replace("/");
    },
    [bookingInEditor, bookings, dispatch, history, isRepeatable]
  );

  const onSaveBooking = useCallback(() => {
    if (!!bookingInEditor.repeat && bookingInEditor?.id) {
      setShowSavePop(true);
    } else {
      // save single booking
      // or save repeated booking when creating new one
      saveBooking(!!bookingInEditor.repeat && !bookingInEditor?.id);
    }
  }, [bookingInEditor, saveBooking]);

  const onDeleteBooking = useCallback(() => {
    if (!!bookingInEditor.repeat && bookingInEditor?.id) {
      setShowDelPop(true);
    } else {
      deleteBooking();
    }
  }, [bookingInEditor, deleteBooking]);

  useEffect(() => {
    if (!bookingInEditor) history.replace("/");
  }, [history, bookingInEditor]);

  return (
    <IonPage className={css.bookingPage}>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/" />
        </IonButtons>
        <IonTitle>排课信息</IonTitle>
      </IonToolbar>
      <IonContent>
        <IonGrid>
          <IonRow className={cx("row", css.row)}>
            <IonCol size="1" className="icon">
              <IonIcon icon={calendar} size="large"></IonIcon>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel className="label">日期</IonLabel>
                <IonDatetime
                  ref={refDate}
                  className="field"
                  max="2030-01-01"
                  value={bookingInEditor?.startTime?.format("YYYY-MM-DD")}
                  placeholder="Select Date"
                  displayFormat="YYYY-MM-DD"
                  onIonChange={updateDate}
                ></IonDatetime>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={cx("row", css.row)}>
            <IonCol size="1" className="icon">
              <IonIcon icon={stopwatch} size="large"></IonIcon>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel className="label">开始时间</IonLabel>
                <select
                  className="field"
                  value={bookingInEditor?.startTime?.format("HH:mm")}
                  onChange={updateTime}
                  ref={refStart}
                >
                  {timeRange.map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={cx("row", css.row)}>
            <IonCol size="1" className="icon">
              <IonIcon icon={alarm} size="large"></IonIcon>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel className="label">结束时间</IonLabel>
                {/* <IonDatetime
                  ref={refEnd}
                  className="field"
                  value={bookingInEditor?.endTime?.format("HH:mm")}
                  displayFormat="HH:mm"
                  minuteValues="0,15,30,45"
                  onIonChange={updateTime}
                ></IonDatetime> */}
                <select
                  className="field"
                  value={bookingInEditor?.endTime?.format("HH:mm")}
                  onChange={updateTime}
                  ref={refEnd}
                >
                  {timeRange.map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="1" className="icon">
              <IonIcon icon={repeat} size="large"></IonIcon>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel className="label">重复</IonLabel>
                <select
                  className="field"
                  value={bookingInEditor?.repeat ?? 0}
                  onChange={updateRepeat}
                  ref={refRepeat}
                >
                  <option value={0}>从不</option>
                  <option value={1}>每日</option>
                  <option value={2}>每周</option>
                  <option value={3}>每月</option>
                </select>
              </IonItem>
            </IonCol>
          </IonRow>

          {!!bookingInEditor.repeat && (
            <>
              {!readonly && (
                <IonRow className={cx("row", css.row)}>
                  <IonCol size="12">
                    <IonItem color="danger">
                      <IonLabel className="warning">
                        修改重复信息将会影响该课程未来的所有重复预定！！
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}

              <IonRow className={cx("row", css.row)}>
                <IonCol size="1" className="icon">
                  {/* <IonIcon icon={contact} size="large"></IonIcon> */}
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel className="label" position="fixed">
                      重复次数
                    </IonLabel>
                    <select
                      className="field"
                      value={bookingInEditor?.repeatTimes ?? -1}
                      onChange={updateRepeatTimes}
                      ref={refRepeatTimes}
                    >
                      <option value={-1}></option>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={40}>40</option>
                    </select>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow className={cx("row", css.row)}>
                <IonCol size="1" className="icon">
                  {/* <IonIcon icon={contact} size="large"></IonIcon> */}
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel className="label" position="fixed">
                      结束日期
                    </IonLabel>
                    <IonDatetime
                      ref={refRepeatEndDate}
                      className="field"
                      max="2030-01-01"
                      value={bookingInEditor?.repeatEndDate?.format(
                        "YYYY-MM-DD"
                      )}
                      placeholder="Select Date"
                      displayFormat="YYYY-MM-DD"
                      onIonChange={updateRepeatEndDate}
                    ></IonDatetime>
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          )}

          <IonRow className={cx("row", css.row)}>
            <IonCol size="1" className="icon">
              <IonIcon icon={contact} size="large"></IonIcon>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel className="label" position="fixed">
                  会员
                </IonLabel>
                <IonInput
                  ref={refMembers}
                  value={bookingInEditor?.customers?.join(", ")}
                  className="members"
                  readonly={true}
                  onClick={() => history.push("/members")}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={cx("row", css.row)}>
            <IonCol size="1" className="icon">
              <IonIcon icon={wifi} size="large"></IonIcon>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel className="label" position="fixed">
                  网课
                </IonLabel>
                <IonCheckbox
                  slot="end"
                  ref={refOnline}
                  checked={bookingInEditor?.online}
                  onIonChange={updateOnline}
                ></IonCheckbox>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow className={cx("row", css.row)}>
            <IonCol size="1" className="icon">
              <IonIcon icon={create} size="large"></IonIcon>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel className="label" position="fixed">
                  备注
                </IonLabel>
                <IonInput
                  ref={refNote}
                  value={bookingInEditor?.note}
                  className="members"
                  onIonChange={updateNote}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            {!readonly && (
              <>
                <IonButton
                  color="primary"
                  className="edit-button"
                  onClick={onSaveBooking}
                >
                  确定/保存
                </IonButton>
                <IonButton
                  color="danger"
                  className="edit-button"
                  onClick={onDeleteBooking}
                  disabled={!bookingInEditor?.id}
                >
                  删除
                </IonButton>
                {!!bookingInEditor.repeat && bookingInEditor?.id && (
                  <>
                    <Drawer
                      open={showDelPop}
                      onClose={() => setShowDelPop(false)}
                      direction="bottom"
                      className={css.popPanel}
                    >
                      <IonList>
                        <IonItem button detail={false} onClick={deleteBooking}>
                          仅删除本次
                        </IonItem>
                        <IonItem
                          button
                          detail={false}
                          lines="none"
                          onClick={deleteRepeatBookings}
                        >
                          删除本次及未来所有重复预定
                        </IonItem>
                      </IonList>
                    </Drawer>

                    <Drawer
                      open={showSavePop}
                      onClose={() => setShowSavePop(false)}
                      direction="bottom"
                      className={css.popPanel}
                    >
                      <IonList>
                        <IonItem
                          button
                          detail={false}
                          onClick={() => saveBooking(false)}
                        >
                          仅更新本次
                        </IonItem>
                        <IonItem
                          button
                          detail={false}
                          lines="none"
                          onClick={() => saveBooking(true)}
                        >
                          更新本次及未来所有重复预定
                        </IonItem>
                      </IonList>
                    </Drawer>
                  </>
                )}
              </>
            )}
            <IonButton
              color="light"
              className="edit-button"
              onClick={() => history.replace("/")}
            >
              取消/返回
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Editor);
