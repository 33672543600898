import { css } from "emotion";

export const dayCell = css`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  font-size: 3.4vmin;

  @media (min-device-width: 1024px) {
    font-size: 2vmin;
  }
`;

export const daysContainer = css`
  display: flex;
  background: lightgrey;
  height: 10vmin;

  @media (min-device-width: 1024px) {
    height: 5vmin;
  }
`;
