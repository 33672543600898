import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { cx } from "emotion";

import config from "../../../config";
import * as css from "./index.css";
import Hours from "./Hours";
import Days from "./Days";
import useStore, { WeekData } from "../../../store.app";
import BookingCell from "./BookingCell";
import { useMount } from "react-use";
import { inWeek, getPeriod } from "../../../utils";
import dayjs from "dayjs";
import { withRouter, RouteComponentProps } from "react-router";

const slotHours = [...Array(config.endHour + 1).keys()].slice(
  config.startHour - config.endHour - 1
);

const slotDays = [...Array(7).keys()];

export type CalendarProps = RouteComponentProps & { week: WeekData };

const Calendar: React.FC<CalendarProps> = ({ week, history }) => {
  const timeLineRef = useRef();
  const [initialized, setInitialized] = useState(false);
  const { currMonday, dispatch } = useStore("currMonday");

  const month = (week.monday.month() + 1).toString().padStart(2, "0");

  const editCell = useCallback(
    (day, hour) => {
      let startTime = week.monday.add(day, "day").add(hour, "hour");
      if (
        week.bookings &&
        week.bookings.find(
          (x) => x.startTime <= startTime && x.endTime > startTime
        )
      )
        startTime = startTime.add(30, "minute");
      let endTime = startTime.add(1, "hour");
      if (
        week.bookings &&
        week.bookings.find((x) => x.startTime < endTime && x.endTime >= endTime)
      )
        endTime = endTime.add(-30, "minute");
      dispatch("setBookingInEditor", {
        startTime,
        endTime,
        id: null,
        customers: null,
      });

      history.push("/edit");
    },
    [dispatch, history, week.bookings, week.monday]
  );

  useMount(() => {
    if (!inWeek(dayjs(), week)) return;
    setInterval(() => {
      if (!timeLineRef.current) return;
      const top =
        ((dayjs().hour() + dayjs().minute() / 60.0 - config.startHour) * 100) /
        (config.endHour - config.startHour + 1);
      (timeLineRef.current as any).style.top = `${top}%`;
    }, 1000);
  });

  useEffect(() => {
    if (week.monday === currMonday && !initialized) {
      setInitialized(true);
    }
  }, [currMonday, initialized, week.monday]);

  return useMemo(
    () => (
      <div className={cx("body", `body-${week.monday.unix()}`, css.body)}>
        <div className={cx("hour-month", css.hourMonth)}>
          <Hours />
          <div className={css.monthYear}>{month}月</div>
        </div>
        <div className={cx("time-table", css.timeTable)}>
          <div className={css.timeSlots}>
            {initialized && inWeek(dayjs(), week) && (
              <div ref={timeLineRef} className={cx("timeline", css.timeline)} />
            )}
            {slotDays.map((sd) => (
              <div
                className={cx("slot-col", css.slotCol, {
                  today:
                    week.monday.add(sd, "day").format("YYYY-MM-DD") ===
                    dayjs().format("YYYY-MM-DD"),
                })}
                key={sd}
              >
                {slotHours.map((sh) => (
                  <div
                    className={cx("slot-cell", css.slotCell)}
                    key={sd + "_" + sh}
                    onClick={() => editCell(sd, sh)}
                  ></div>
                ))}
              </div>
            ))}
            {initialized &&
              week.bookings &&
              week.bookings.map((x) => <BookingCell key={x.id} booking={x} />)}
          </div>
          <div className={css.days}>
            <Days monday={week.monday} />
          </div>
        </div>
      </div>
    ),
    [week, month, initialized, editCell]
  );
};

export default withRouter(Calendar);
