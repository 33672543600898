import { css } from "emotion";

export const bookingPage = css`
  .icon {
    color: #777;
    display: flex;
    justify-content: flex-end;
  }

  .label,
  .field,
  .datetime-text {
    font-size: 14px;
  }

  .label {
    width: 60px;
    min-width: 60px;
    flex-basis: 60px;
  }

  .warning {
    font-size: 12px;
  }

  .members {
    text-align: right;
  }

  .edit-button {
    height: 40px;
    font-size: 12px;
    width: 70px;
  }
`;

export const row = css`
  display: flex;
  align-items: center;
`;

export const popPanel = css`
  height: auto !important;
`;
