import { css } from "emotion";

export const memberListPage = css`
  .selected-members {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    z-index: 999;
    background-color: whitesmoke;
    width: 100%;
  }

  .member-list {
    margin-top: 60px;
  }
`;
