import { css } from "emotion";

export const menu = css`
  height: 100%;
  width: 100%;
  z-index: 999;
  position: absolute;
  left: 100%;
  top: 0;
  transition: all 0.3s ease-out;

  &.open {
    left: 0;
  }

  .inner-menu {
    width: 60%;
    height: 100%;
    float: right;
    box-shadow: -3px 0px 10px 1px #aaa;
  }
`;
