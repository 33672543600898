import React from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import StoreContext from "storeon/react/context";

/* Theme variables */
import "./theme/variables.css";

import { store } from "./store.app";
import useAppInit from "./firebase";
import Loader from "./components/Loader";
import BookingEdit from "./pages/BookingEdit";
import MemberList from "./pages/MemberList";

const App: React.FC = () => {
  useAppInit();
  return (
    <IonApp>
      <Loader />
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/home" component={Home} exact={true} />
          <Route path="/edit" component={BookingEdit} exact={true} />
          <Route path="/members" component={MemberList} exact={true} />
          <Route exact path="/" render={() => <Redirect to="/home" />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default () => (
  <StoreContext.Provider value={store}>
    <App />
  </StoreContext.Provider>
);
