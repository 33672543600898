import { css } from "emotion";

export const slider = css`
  height: 100%;

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }
`;

export const menu = css`
  z-index: 888;
  position: absolute;
  right: 0;
  top: -4px;
  cursor: pointer;

  > * {
    color: forestgreen;
  }
`;
