import React, { useCallback, useRef } from "react";
import { IonItem, IonInput, IonButton, IonModal, IonText } from "@ionic/react";
import { css } from "emotion";
import useStore from "../store.app";

const modal = css`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 20px;

  .modal-title {
    font-size: 20px;
    text-align: center;
  }
  .modal-sub-title {
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
`;

const AddMember: React.FC<{ hideModal: () => void }> = ({ hideModal }) => {
  const { dispatch } = useStore();
  const refMemInput = useRef();

  const addMembers = useCallback(() => {
    const ref = refMemInput.current as any;
    if (ref && ref.value) {
      dispatch("addMembers", ref.value);
    }
    hideModal();
  }, [dispatch, hideModal]);

  return (
    <IonModal isOpen={true}>
      <div className={modal}>
        <IonText className="modal-title">创建新客户</IonText>
        <IonText className="modal-sub-title">多个客户可用空格隔开</IonText>
        <IonItem>
          <IonInput ref={refMemInput} />
        </IonItem>
        <div className="modal-buttons">
          <IonButton onClick={hideModal} color="light">
            取消
          </IonButton>
          <IonButton onClick={addMembers} color="primary">
            确定
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default AddMember;
