import React, { useCallback } from "react";
import { cx } from "emotion";
import * as css from "./BookingCell.css";
import config from "../../../config";
import useStore, { Booking, WeekData } from "../../../store.app";
import { RouteComponentProps, withRouter } from "react-router";
const { Textfit } = require("react-textfit");

function getPosition(booking: Booking) {
  const hours = config.endHour - config.startHour + 1;
  const startOfDay = booking.startTime
    .startOf("day")
    .add(config.startHour, "hour");
  const dayFromMonday = startOfDay.day() === 0 ? 6 : startOfDay.day() - 1;
  const bookingHours = booking.endTime.diff(booking.startTime, "hour", true);
  return {
    top: `${
      (booking.startTime.diff(startOfDay, "hour", true) * 100.0) / hours
    }%`,
    left: `${(dayFromMonday * 100.0) / 7}%`,
    width: `calc(${100.0 / 7}% - 2px)`,
    height: `calc(${(100.0 * bookingHours) / hours}% - 2px)`,
  };
}

type BookingCellProps = {
  booking?: Booking;
} & RouteComponentProps;

const BookingCell: React.FC<BookingCellProps> = ({ history, booking }) => {
  const { dispatch, showNames, nameFilter } = useStore(
    "showNames",
    "nameFilter"
  );
  const editBooking = useCallback(
    (evt) => {
      evt.preventDefault();
      dispatch("setBookingInEditor", booking);
      history.push("/edit");
    },
    [booking, history, dispatch]
  );

  if (
    !booking?.customers?.some(
      (c) => (nameFilter || "") === "" || nameFilter === c
    )
  )
    return null;

  return (
    <div
      className={cx("booking", css.booking)}
      style={getPosition(booking)}
      onClick={editBooking}
    >
      <Textfit
        className={cx("inner-booking", booking?.online && "online")}
        mode="multi"
        max={25}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: showNames
              ? booking?.customers
                  ?.filter((c) => (nameFilter || "") === "" || nameFilter === c)
                  .join("<br />")
              : booking?.customers?.length?.toString() ?? "",
          }}
        />
      </Textfit>
    </div>
  );
};

export default withRouter(BookingCell);
