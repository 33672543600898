import React, { useCallback, useEffect, useState } from "react";
import {
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonLabel,
  IonIcon,
  IonItem,
  IonChip,
  IonList,
  IonFab,
  IonFabButton
} from "@ionic/react";
import { closeCircle, add, close } from "ionicons/icons";
import * as css from "./MemberList.css";
import useStore from "../store.app";
import { RouteComponentProps, withRouter } from "react-router";
import AddMember from "./AddMember";

type MemberListProps = RouteComponentProps & {};

const Members: React.FC<MemberListProps> = ({ history }) => {
  const [showModal, setShowModal] = useState(false);
  const { bookingInEditor, dispatch, members } = useStore(
    "bookingInEditor",
    "members"
  );
  const remove = useCallback(
    m => {
      const customers = (bookingInEditor?.customers || []).filter(x => x !== m);
      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        customers
      });
    },
    [bookingInEditor, dispatch]
  );

  const selectMember = useCallback(
    m => {
      const customers = bookingInEditor?.customers || [];
      if (customers.indexOf(m) < 0) customers.push(m);
      dispatch("setBookingInEditor", {
        ...bookingInEditor,
        customers
      });
    },
    [bookingInEditor, dispatch]
  );

  const deleteMember = useCallback(
    (e: any, m: string) => {
      if (window.confirm(`确定要删除会员${m}吗?`)) dispatch("deleteMember", m);
      e.stopPropagation();
    },
    [dispatch]
  );

  useEffect(() => {
    if (!bookingInEditor) {
      history.replace("/");
      return;
    }
  }, [bookingInEditor, history]);

  return (
    <IonPage className={css.memberListPage}>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/" />
        </IonButtons>
        <IonTitle>会员列表</IonTitle>
      </IonToolbar>
      <IonContent>
        <div className="selected-members">
          {(bookingInEditor?.customers || []).map(m => (
            <IonChip outline color="primary" key={m}>
              <IonLabel>{m}</IonLabel>
              <IonIcon icon={closeCircle} onClick={() => remove(m)}></IonIcon>
            </IonChip>
          ))}
        </div>
        <IonList className="member-list">
          {members?.map(x => (
            <IonItem
              key={x}
              button
              detail={false}
              onClick={() => selectMember(x)}
              className="member-item"
            >
              <IonLabel>{x}</IonLabel>
              <IonIcon icon={close} onClick={(e:any) => deleteMember(e, x)}></IonIcon>
            </IonItem>
          ))}
        </IonList>
        {showModal && <AddMember hideModal={() => setShowModal(false)} />}
      </IonContent>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton color="danger">
          <IonIcon icon={add} onClick={() => setShowModal(true)} />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};

export default withRouter(Members);
