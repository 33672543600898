import uniqBy from "lodash/uniqBy";
import cloneDeep from "lodash/cloneDeep";
import createStore, { Module, StoreonEvents } from "storeon";
import useStoreon from "storeon/react"; // or storeon/preact
import { getPeriod, Week, inWeek } from "./utils";
import { Dayjs } from "dayjs";
import {
  deleteBooking,
  deleteRepeatBookings,
  saveBooking,
  addCustomers,
  getCustomers,
  deleteCustomer,
} from "./service";
import { logout } from "./firebase";

export interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

export interface Repeat {
  repeat?: number;
  repeatTimes?: number;
  repeatEndDate?: Dayjs;
  repeatSeries?: string; // repeat id, all booking items of same repeat have same value
}

export type Booking = {
  id: string;
  startTime: Dayjs;
  endTime: Dayjs;
  note?: string;
  customers: string[];
  online?: boolean;
} & Repeat;

export interface WeekData extends Week {
  bookings?: Booking[];
}
// State structure
export interface AppState {
  loading: boolean;
  loadInProgress: number;
  weeks: WeekData[];
  currMonday: Dayjs;
  bookingInEditor: Booking;
  bookings: Booking[];
  members: string[];
  showNames: boolean;
  nameFilter: string;
}

// Events declaration: map of event names to type of event data
export interface AppEvents extends StoreonEvents<AppState> {
  // `set` event which goes with number as data
  setLoading: boolean;
  setBookings: Booking[];
  setCurrMonday: Dayjs;
  setBookingInEditor: Booking;
  deleteBooking: string;
  deleteRepeatBookings: Booking;
  saveBooking: { booking: Booking; saveRepeat?: boolean };
  loadMembers: undefined;
  setMembers: string[];
  addMembers: string;
  deleteMember: string;
  setShowNames: boolean;
  setNameFilter: string;
  logout: undefined;
}

const totalWeeks = 26;
const weeks = [...Array(totalWeeks).keys()].map((x) =>
  getPeriod(null, { value: x - 13, unit: "week" })
);

function updateWeeks(weeks: WeekData[], bookings: Booking[]) {
  return weeks.map((x) => ({
    ...x,
    bookings: bookings.filter((y) => inWeek(y.startTime, x)),
  }));
}

const appModule: Module<AppState, AppEvents> = (store) => {
  store.on("@init", () => ({
    loading: true,
    loadInProgress: 0,
    weeks,
    currMonday: null,
    bookingInEditor: null,
    showNames: true,
  }));
  store.on("logout", () => {
    logout();
  });
  store.on("setLoading", (state, event) => {
    const loadInProgress = event
      ? state.loadInProgress + 1
      : state.loadInProgress - 1;
    return {
      loading: loadInProgress !== 0,
      loadInProgress,
    };
  });
  store.on("setBookings", (state, event) => ({
    bookings: event,
    weeks: updateWeeks(state.weeks, event),
  }));
  store.on("setCurrMonday", (state, event) => ({
    currMonday: event,
  }));
  store.on("setBookingInEditor", (state, event) => ({
    bookingInEditor: cloneDeep(event),
  }));
  store.on("deleteBooking", async (state, event) => {
    await deleteBooking(event);
    const bookings = state.bookings.filter((x) => x.id !== event);
    store.dispatch("setBookings", bookings);
  });
  store.on("deleteRepeatBookings", async (state, event) => {
    const deleted = await deleteRepeatBookings(event);
    const bookings = state.bookings.filter(
      (x) => !deleted.some((d) => d === x.id)
    );
    store.dispatch("setBookings", bookings);
  });
  store.on("saveBooking", async (state, event) => {
    const { added, deleted } = await saveBooking(
      event.booking,
      event.saveRepeat
    );
    const bookings = uniqBy(
      [
        ...added,
        ...state.bookings.filter((x) => !deleted.some((d) => d === x.id)),
      ],
      "id"
    );
    store.dispatch("setBookings", bookings);
  });
  store.on("loadMembers", async () => {
    const members = await getCustomers();
    store.dispatch("setMembers", members);
  });
  store.on("setMembers", (state, event) => ({ members: event }));
  store.on("addMembers", async (state, event) => {
    if (!event) return;
    const members = event.split(",").map((x) => x.trim());
    await addCustomers(members);
    store.dispatch("loadMembers");
  });
  store.on("deleteMember", async (state, event) => {
    await deleteCustomer(event);
    store.dispatch("loadMembers");
  });
  store.on("setShowNames", (state, event) => ({
    showNames: event,
  }));
  store.on("setNameFilter", (state, event) => ({
    nameFilter: event,
  }));
};

export const store = createStore([
  appModule,
  process.env.NODE_ENV !== "production" && require("storeon/devtools"),
]);

const useStore = (...keys: (keyof AppState)[]) =>
  useStoreon<AppState, AppEvents>(...keys);

export default useStore;
