import { css } from "emotion";

export const hoursContainer = css`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;
export const hourLabel = css`
  margin-top: -1.7vmin;
  font-size: 3.4vmin;

  @media (min-device-width: 1024px) {
    margin-top: -1vmin;
    font-size: 2vmin;
  }
`;

export const hourCell = css`
  flex: 1 1 auto;
`;
