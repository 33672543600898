import React from "react";
import { IonLoading } from "@ionic/react";

import useStore from "../store.app";

export default () => {
  const { loading } = useStore("loading");
  return (
    <IonLoading
      isOpen={loading}
      onDidDismiss={() => {
        /* loading = false */
      }}
      message={"Loading..."}
      duration={5000}
    />
  );
};
