import { css } from "emotion";

export const body = css`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  padding-top: 20px;
  position: relative;
  overflow: hidden;
`;

export const timeTable = css`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const timeSlots = css`
  flex: 1 1 auto;
  display: flex;
  position: relative;
  align-items: stretch;
`;

export const days = css``;

export const slotCol = css`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  border-bottom: 1px grey solid;

  &.today {
    background-color: #f0f0f0;
  }
`;

export const slotCell = css`
  border-width: 1px 0 0 1px;
  border-color: grey;
  border-style: solid;
  flex: 1 1 auto;
`;

export const monthYear = css`
  height: 10vmin;
  font-size: 4vmin;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-device-width: 1024px) {
    height: 5vmin;
    font-size: 2.4vmin;
  }
`;

export const hourMonth = css`
  width: 10vmin;
  display: flex;
  flex-direction: column;

  @media (min-device-width: 1024px) {
    width: 6vmin;
  }
`;

export const timeline = css`
  top: 0;
  left: -12vmin;
  height: 1px;
  width: 130%;
  z-index: 999;
  position: absolute;
  background-color: red;
`;
