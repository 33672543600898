import React from "react";
import config from "../../../config";

import * as css from "./Hours.css";

const Hours: React.FC = () => {
  const hours = [...Array(config.endHour + 1).keys()].slice(
    config.startHour - config.endHour - 1
  );

  return (
    <div className={css.hoursContainer}>
      {hours.map(hour => (
        <div className={css.hourCell} key={hour}>
          <div className={css.hourLabel}>
            {hour.toString().padStart(2, "0")}:00
          </div>
        </div>
      ))}
    </div>
  );
};

export default Hours;
