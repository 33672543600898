import React, { useState, useEffect } from "react";
import { cx } from "emotion";
import * as css from "./Menu.css";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonRadio,
  IonCheckbox,
  IonButton,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import useStore from "../../store.app";

const Menu: React.FC<{
  isOpen?: boolean;
  setIsOpen?: (isOpen?: boolean) => void;
}> = (props) => {
  const { members, showNames, nameFilter, dispatch } = useStore(
    "members",
    "showNames",
    "nameFilter"
  );

  return (
    <div
      className={cx("menu", css.menu, { open: props.isOpen })}
      onClick={() => props.setIsOpen?.(false)}
    >
      <div className="inner-menu" onClick={(e) => e.stopPropagation()}>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel>显示人名</IonLabel>
              <IonCheckbox
                checked={showNames}
                onIonChange={(e) => {
                  dispatch("setShowNames", e.detail.checked);
                }}
              />
            </IonItem>
            <IonItem>
              <IonLabel>按人过滤</IonLabel>
              <IonSelect
                value={nameFilter}
                interface="popover"
                onIonChange={(e) => dispatch("setNameFilter", e.detail.value)}
              >
                <IonSelectOption value=""></IonSelectOption>
                {members?.map((x) => (
                  <IonSelectOption key={x} value={x}>
                    {x}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonButton
                slot="end"
                onClick={() => {
                  dispatch("setShowNames", true);
                  dispatch("setNameFilter", "");
                }}
              >
                重置
              </IonButton>
            </IonItem>
            <IonItem>
              <IonButton
                slot="end"
                onClick={() => {
                  dispatch("setLoading", true);
                  dispatch("logout");
                }}
              >
                退出登陆
              </IonButton>
            </IonItem>
          </IonList>
        </IonContent>
      </div>
    </div>
  );
};

export default Menu;
