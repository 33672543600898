import React from "react";
import dayjs from "dayjs";

import * as css from "./Days.css";

const Days: React.FC<{ monday: any }> = ({ monday }) => {
  const firstDay = monday || "2019-12-02";

  const dayNames = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];

  const days = [...Array(7).keys()].map(x => dayjs(firstDay).add(x, "day"));

  return (
    <div className={css.daysContainer}>
      {days.map(day => (
        <div className={css.dayCell} key={day.date()}>
          {day
            .date()
            .toString()
            .padStart(2, "0")}{" "}
          <br /> {dayNames[day.day()]}
        </div>
      ))}
    </div>
  );
};

export default Days;
