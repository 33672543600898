import { css } from "emotion";
import wifi from "../../../images/wifi.svg";

export const booking = css`
  position: absolute;
  margin: 1px;
  border: solid 1px lightgreen;

  .inner-booking {
    background-color: darkgreen;
    width: 100%;
    height: 100%;
    font-size: 2vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden;
  }

  .inner-booking > div {
    z-index: 999;
  }

  .inner-booking.online::before {
    content: "";
    background-image: url(${wifi});
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
  }
`;
