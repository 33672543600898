// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signOut,
  signInWithRedirect,
  setPersistence,
  getRedirectResult,
  onAuthStateChanged,
  onIdTokenChanged,
  browserLocalPersistence,
} from "firebase/auth";
import {
  getFirestore as getFirestoreRaw,
  enableIndexedDbPersistence,
} from "firebase/firestore";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import { getAnalytics } from "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import { useMount } from "react-use";
import createStore from "storeon";

import useStore, { AppEvents } from "./store.app"; // or storeon/preact

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAfylnJZmrWEHNm4CiwyDMp_C8ltz52kLc",
  authDomain: "www.jdxfit.com",
  projectId: "jdxfit-com",
  storageBucket: "jdxfit-com.appspot.com",
  messagingSenderId: "145087885406",
  appId: "1:145087885406:web:8afa31b4a03264a7d784cf",
  measurementId: "G-BJYTR8CPCQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestoreRaw(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const logout = async () => {
  await signOut(auth);
  window.location.reload();
};

async function init(dispatch: createStore.Dispatch<AppEvents>) {
  // show loader
  dispatch("setLoading", true);

  await enableIndexedDbPersistence(db).catch((err) =>
    console.error(err.code || err)
  );

  setPersistence(auth, browserLocalPersistence).catch((err: any) =>
    console.error(err.code || err)
  );

  onIdTokenChanged(
    auth,
    function (user: any) {
      if (user) {
        user.getIdToken().then(function (accessToken: any) {});
        // document.getElementById("loader").style.display = "none";
        dispatch("setLoading", false);
      } else {
        console.log("User is not signed in");
        setTimeout(() => signInWithRedirect(auth, provider), 3000);
      }
    },
    function (error: any) {
      console.error(error);
    }
  );
}

function useAppInit() {
  const { dispatch } = useStore("loading");
  useMount(() => {
    init(dispatch);
  });
}

export const getFirestore = () => db;
export default useAppInit;
