import firebase from "firebase/app";
import { getFirestore } from "./firebase";
import { Booking } from "./store.app";
import difference from "lodash/difference";
import { customAlphabet } from "nanoid";
import { repeatDates, toDate, clearRepeat } from "./utils";
import dayjs from "dayjs";
import {
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  QuerySnapshot,
  query,
  where,
  orderBy,
  writeBatch,
} from "firebase/firestore";

const nanoid = customAlphabet(
  // "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  // 18
  "0123456789abcdefghijklmnopqrstuvwxyz",
  6
);
export function uniqid(prefix?: string) {
  return (prefix ?? "") + nanoid();
}

export async function getCustomers() {
  const members = await getCustomerEntities();

  return members
    .map((x) => x.id)
    .sort(new Intl.Collator("zh-Hans-CN-u-co-gb2312").compare);
}

let fire_customers: QueryDocumentSnapshot[] = null;
async function getCustomerEntities() {
  if (fire_customers) return fire_customers;
  const querySnapshot = collection(getFirestore(), "customer");
  return (fire_customers = (await getDocs(querySnapshot)).docs);
}

export async function addCustomers(customers: string[]) {
  if (customers.length === 0) return;
  const members = await getCustomers();
  customers = difference(customers, members);
  if (customers.length === 0) return;
  try {
    for (var member of customers) {
      await setDoc(
        doc(getFirestore(), "customer", member),
        { name: member },
        { merge: true }
      );
    }
    fire_customers = null;
  } catch (ex) {
    alert(`添加失败：${ex}`);
    // throw ex;
  }
}

export async function deleteCustomer(customer: string) {
  if (!customer) return;
  await deleteDoc(doc(getFirestore(), "customer", customer));
  fire_customers = null;
}

export function convertSnapshotToBookings(snapshot: QuerySnapshot) {
  return snapshot.docs
    .map((z) => ({ id: z.id, ...z.data() }))
    .map((x: any) => ({
      ...x,
      id: x.id,
      startTime: toDate(x.startTime),
      endTime: toDate(x.endTime),
      repeatEndDate:
        x.repeatEndDate == null ? x.repeatEndDate : toDate(x.repeatEndDate),
      customers: x.customers.map((y: any) => y.id),
    })) as Booking[];
}

export async function getBookings(startTime: Date, endTime: Date) {
  return getDocs(
    query(
      collection(getFirestore(), "booking"),
      where("startTime", ">=", startTime),
      where("startTime", "<", endTime),
      orderBy("startTime")
    )
  );
}

export async function deleteBooking(bookingId: string) {
  if (!bookingId) return;
  try {
    return deleteDoc(doc(getFirestore(), "booking", bookingId));
  } catch (ex) {
    alert(`删除失败：${ex}`);
    // throw ex;
  }
}

export async function deleteRepeatBookings(data: Booking) {
  if (!data?.repeatSeries) return [];

  const db = getFirestore();
  const coll = collection(db, "booking");
  const batch = writeBatch(db);

  const deleted = [] as string[];
  const toDel = await getDocs(
    query(
      coll,
      where("repeatSeries", "==", data.repeatSeries),
      where("startTime", ">=", data.startTime.toDate())
    )
  );
  toDel.forEach((x) => {
    deleted.push(x.ref.id);
    batch.delete(x.ref);
  });

  try {
    await batch.commit();
    return deleted;
  } catch (ex) {
    alert(`保存失败：${ex}`);
    throw ex;
  }
}

export async function saveBooking(booking: Booking, saveRepeat?: boolean) {
  const bookingCustomers = booking.customers || [];
  const members = await getCustomerEntities();
  const customers = members
    .filter((m) => bookingCustomers.includes(m.id))
    .map((x) => x.ref);

  if (customers.length === 0) {
    alert(`保存失败：请选择会员！`);
    throw new Error(`保存失败：请选择会员！`);
  }

  // cleara repeat info when saving single item
  if (!saveRepeat) clearRepeat(booking);

  const data = {
    ...booking,
    startTime: booking.startTime.toDate(),
    endTime: booking.endTime.toDate(),
    customers,
    note: booking.note || "",
    repeat: booking.repeat || 0,
    repeatTimes: booking.repeatTimes || null,
    repeatEndDate: booking.repeatEndDate?.toDate() || null,
  };

  const db = getFirestore();
  const coll = collection(db, "booking");
  const batch = writeBatch(db);

  const deleted = [] as string[];
  if (saveRepeat && data.repeatSeries) {
    // delete all repeat items in future first
    const toDel = await getDocs(
      query(
        coll,
        where("repeatSeries", "==", data.repeatSeries),
        where("startTime", ">=", data.startTime)
      )
    );
    toDel.forEach((x) => {
      deleted.push(x.ref.id);
      batch.delete(x.ref);
    });
  } else if (data.id) {
    deleted.push(data.id);
    batch.delete(doc(coll, data.id));
  }

  // add new future repeat items
  const repeatSeries = uniqid();
  const allStarts = saveRepeat
    ? repeatDates(data.startTime, booking)
    : [dayjs(data.startTime)];
  const allEnds = saveRepeat
    ? repeatDates(data.endTime, booking)
    : [dayjs(data.endTime)];
  allStarts.forEach((start, i) => {
    // const id = uniqid(`${start.format("YYYY-MM-DD-HH-mm")}-`);
    const id = start.format("YYYY-MM-DD-HH-mm");
    batch.set(doc(coll, id), {
      ...data,
      id,
      repeatSeries,
      startTime: start.toDate(),
      endTime: allEnds[i].toDate(),
    });
  });

  try {
    await batch.commit();
    const snapshot = await getDocs(
      query(
        coll,
        where("repeatSeries", "==", repeatSeries),
        where("startTime", ">=", data.startTime)
      )
    );
    return { added: convertSnapshotToBookings(snapshot), deleted };
  } catch (ex) {
    alert(`保存失败：${ex}`);
    throw ex;
  }
}
