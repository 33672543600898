import dayjs, { Dayjs, OpUnitType } from "dayjs";
import { Timestamp, Booking, Repeat } from "./store.app";

export type DateType = Date | string | Dayjs;

export type Week = { monday: Dayjs; sunday: Dayjs };

export function toDate(ts: Timestamp) {
  return dayjs.unix(ts.seconds);
}

export function inWeek(time: DateType, week: Week) {
  const dTime = dayjs(time);
  return dTime >= week.monday && dTime < week.sunday.add(1, "day");
}

export function getPeriod(
  date?: DateType,
  offset?: { value: number; unit: dayjs.OpUnitType }
): Week {
  const safeDate = dayjs(date || undefined).startOf("day");
  const day = dayjs(safeDate).day();
  const diff = day ? 1 - day : -6;
  let monday = dayjs(safeDate).add(diff, "day");

  if (offset) {
    monday = monday.add(offset.value, offset.unit);
  }

  const sunday = monday.add(6, "day");
  return { monday, sunday };
}

export function getDate(datetime: Dayjs) {
  return datetime.startOf("day");
}

export function getTimeString(datetime: Dayjs, format: string = "HH:mm") {
  datetime = dayjs(datetime || undefined);
  return datetime.format(format);
}

export function timeStringToDate(time: string, date?: Dayjs) {
  date = dayjs(date || undefined);
  return dayjs(`${getDate(date).format("YYYY-MM-DD")} ${time}`);
}

export function inPeriod(
  datetime: DateType,
  period: { startTime: DateType; endTime: DateType }
) {
  return datetime >= period.startTime && datetime < period.endTime;
}

export function repeatDates(datetime: DateType, repeat: Repeat) {
  const first = dayjs(datetime);
  const dts = [first];
  const units = ["day", "week", "month"];
  if ((repeat.repeat ?? 0) < 1) return dts;

  for (var i = 1; i < repeat.repeatTimes; i++) {
    dts.push(first.add(i, units[repeat.repeat - 1] as OpUnitType));
  }
  if (repeat.repeatEndDate) {
    let dt = first;
    dt = dt.add(1, units[repeat.repeat - 1] as OpUnitType);
    while (dt <= repeat.repeatEndDate) {
      dts.push(dt);
      dt = dt.add(1, units[repeat.repeat - 1] as OpUnitType);
    }
  }
  return dts;
}

export function isRepeatSameAs(repeat1: Repeat, repeat2: Repeat) {
  return (
    repeat1?.repeat === repeat2?.repeat &&
    repeat1?.repeatEndDate === repeat2?.repeatEndDate &&
    repeat1?.repeatSeries === repeat2?.repeatSeries &&
    repeat1?.repeatTimes === repeat2?.repeatTimes
  );
}

export function clearRepeat(repeat: Repeat) {
  repeat.repeat = null;
  repeat.repeatEndDate = null;
  repeat.repeatSeries = null;
  repeat.repeatTimes = null;
}
