import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonRefresher,
  IonRefresherContent,
  IonIcon,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { more } from "ionicons/icons";
import React, { useRef, useCallback, useState } from "react";
import dayjs from "dayjs";
import { cx } from "emotion";

import * as css from "./index.css";
import useStore from "../../store.app";
import Calendar from "./Calendar";
import { useMount } from "react-use";
import { getBookings, convertSnapshotToBookings } from "../../service";
import { toDate } from "../../utils";
import Menu from "./Menu";

const Home: React.FC = () => {
  const sliderRef = useRef();
  const { dispatch, weeks } = useStore("weeks");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const doRefresh = useCallback(
    (event?: CustomEvent<RefresherEventDetail>) => {
      if (!event) dispatch("setLoading", true);
      const startTime = dayjs(weeks[0].monday).toDate();
      const endTime = dayjs(weeks[weeks.length - 1].sunday)
        .add(1, "day")
        .toDate();
      getBookings(startTime, endTime)
        .then((snapshot) => {
          const bookings = convertSnapshotToBookings(snapshot);
          dispatch("setBookings", bookings);
          dispatch("loadMembers");
        })
        .finally(() => {
          if (!event) dispatch("setLoading", false);
          else event.detail.complete();
        });
    },
    [dispatch, weeks]
  );

  useMount(doRefresh);

  const onIonSlideChange = async (event: any) => {
    const index = await (sliderRef.current as any).getActiveIndex();
    dispatch("setCurrMonday", weeks[index].monday);
  };

  const slideOpts = {
    initialSlide: weeks.length / 2,
    on: {
      ionSlideDidChange: (...args: any[]) => console.log(JSON.stringify(args)),
    },
  };

  return (
    <IonPage>
      <IonContent scrollY={false}>
        <div className={css.menu}>
          <IonIcon
            icon={more}
            size="large"
            onClick={() => {
              setMenuIsOpen(true);
            }}
          />
        </div>
        <Menu isOpen={menuIsOpen} setIsOpen={setMenuIsOpen} />
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonSlides
          ref={sliderRef}
          className={cx("slider", css.slider)}
          options={slideOpts}
          onIonSlideDidChange={onIonSlideChange}
        >
          {weeks.map((x) => (
            <IonSlide key={x.monday.unix()}>
              <Calendar week={x} />
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default Home;
